var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.messages,
          "items-per-page": 10,
          loading: _vm.loading,
          dense: "",
          "loading-text": "Retrieving messages ... please wait",
          "loader-height": 6,
          "hide-default-footer": _vm.loading
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-toolbar",
                  {
                    staticClass: "mb-4",
                    attrs: { color: "secondary", dark: "", dense: "" }
                  },
                  [
                    _c("v-toolbar-title", [_vm._v("Messages")]),
                    _c(
                      "v-chip",
                      {
                        staticClass: "ml-2 secondary darken-2",
                        attrs: { small: "" }
                      },
                      [_vm._v(_vm._s(_vm.messages.length))]
                    ),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.filterMessages(_vm.filter)
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v("mdi-refresh")])],
                      1
                    ),
                    _vm.filtered
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "primary--text",
                            attrs: {
                              small: "",
                              color: "secondary lighten-3",
                              link: ""
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.clearFilter($event)
                              }
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                staticClass: "primary--text",
                                attrs: { small: "" }
                              },
                              [_vm._v("mdi-close")]
                            ),
                            _vm._v("Clear Filter ")
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-dialog",
                      {
                        attrs: { width: "500" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { icon: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-filter")])],
                                  1
                                )
                              ]
                            }
                          }
                        ]),
                        model: {
                          value: _vm.dialog,
                          callback: function($$v) {
                            _vm.dialog = $$v
                          },
                          expression: "dialog"
                        }
                      },
                      [
                        _vm.dialog
                          ? _c("messages-filter", {
                              attrs: { filter: _vm.filter },
                              on: {
                                close: function($event) {
                                  _vm.dialog = false
                                },
                                "filter-messages": function($event) {
                                  return _vm.filterMessages($event)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.created_at",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("div", { staticClass: "d-flex flex-column my-1" }, [
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(item.created_at.split("T")[0])
                    }
                  }),
                  _c("span", {
                    domProps: {
                      textContent: _vm._s(item.created_at.split("T")[1])
                    }
                  })
                ])
              ]
            }
          },
          {
            key: "item.order_id",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("span", [_vm._v(_vm._s(item.order_id))]),
                _c("psi-copy-button", {
                  attrs: {
                    value: item.order_id,
                    dense: "",
                    "btn-class": "my-n2 ml-2",
                    label: "Order ID"
                  }
                })
              ]
            }
          },
          {
            key: "item.status",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    class:
                      item.status == "error" || item.status == "undelivered"
                        ? "white--text"
                        : "primary--text text--darken-4 ",
                    staticStyle: { width: "80px" },
                    attrs: {
                      small: "",
                      color:
                        item.status == "error" || item.status == "undelivered"
                          ? "error"
                          : "success"
                    }
                  },
                  [
                    _c("span", { staticClass: "mx-auto" }, [
                      _vm._v(_vm._s(item.status))
                    ])
                  ]
                )
              ]
            }
          },
          {
            key: "item.body",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("psi-copy-button", {
                  attrs: {
                    dense: "",
                    value: item.body,
                    label: "Message",
                    btnClass: "error--text"
                  }
                }),
                _c("span", [_vm._v(_vm._s(item.body))])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }