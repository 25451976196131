<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="messages"
            :items-per-page="10"
            :loading="loading"
            dense
            loading-text="Retrieving messages ... please wait"
            :loader-height="6"
            :hide-default-footer="loading"
        >
            <template v-slot:top>
                <v-toolbar color="secondary" dark dense class="mb-4">
                    <v-toolbar-title>Messages</v-toolbar-title>
                    <v-chip small class="ml-2 secondary darken-2">{{messages.length}}</v-chip>
                    <v-spacer></v-spacer>
                    <v-btn icon @click.stop="filterMessages(filter)">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <v-chip
                        v-if="filtered"
                        small
                        color="secondary lighten-3"
                        class="primary--text"
                        link
                        @click.stop="clearFilter"
                    >
                        <v-icon small class="primary--text">mdi-close</v-icon>Clear Filter
                    </v-chip>

                    <v-dialog v-model="dialog" width="500">
                        <template v-slot:activator="{on, attrs}">
                            <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon>mdi-filter</v-icon>
                            </v-btn>
                        </template>
                        <messages-filter
                            @close="dialog=false"
                            @filter-messages="filterMessages($event)"
                            v-if="dialog"
                            :filter="filter"
                        ></messages-filter>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.created_at="{item}">
                <div class="d-flex flex-column my-1">
                    <span v-text="item.created_at.split('T')[0]"></span>
                    <span v-text="item.created_at.split('T')[1]"></span>
                </div>
            </template>
            <template v-slot:item.order_id="{item}">
                <span>{{item.order_id}}</span>
                <psi-copy-button
                    :value="item.order_id"
                    dense
                    btn-class="my-n2 ml-2"
                    label="Order ID"
                ></psi-copy-button>
            </template>
            <template v-slot:item.status="{item}">
                <v-chip
                    style="width:80px"
                    small
                    :color="item.status == 'error' || item.status == 'undelivered' ? 'error' : 'success'"
                    :class="item.status == 'error' || item.status == 'undelivered' ? 'white--text' : 'primary--text text--darken-4 '"
                >
                    <span class="mx-auto">{{item.status}}</span>
                </v-chip>
            </template>
            <template v-slot:item.body="{item}">
                <psi-copy-button dense :value="item.body" label="Message" btnClass="error--text"></psi-copy-button>
                <span>{{item.body}}</span>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "messages-list",
    components: {
        MessagesFilter: () => import("./MessagesFilter"),
    },
    props: {
        status: {
            type: String,
            required: false,
            default: "",
        },
        order_id: {
            type: String,
            required: false,
            default: "",
        },
    },
    // Defaults for the filter

    data() {
        return {
            filtered: this.status || this.order_id,
            filter: {
                daterange: "7",
                status: this.status,
                order_id: this.order_id,
            },
            dialog: false,
            headers: [
                {
                    text: "ID",
                    align: "start",
                    width: "80",
                    sortable: true,
                    value: "id",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Status",
                    align: "start",
                    sortable: true,
                    value: "status",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Created",
                    align: "start",
                    sortable: true,
                    width: "120",
                    value: "created_at",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Order ID",
                    align: "start",
                    sortable: true,
                    width: "130",
                    value: "order_id",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "To",
                    align: "start",
                    sortable: true,
                    width: "180",
                    value: "to",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Subject",
                    align: "start",
                    sortable: true,
                    width: "280",
                    value: "subject",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Message",
                    align: "start",
                    sortable: false,
                    value: "body",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("Message", ["loading", "messages"]),
    },
    watch: {
        filter: {
            immediate: true,
            deep: true,
            handler(val) {
                this.filtered =
                    val.status.length > 0 ||
                    val.order_id.length > 0 ||
                    val.daterange != "7";
            },
        },
    },
    mounted() {
        this.getMessages(this.filter);
    },
    methods: {
        ...mapActions("Message", ["getMessages"]),
        filterMessages(filter) {
            this.filter = filter;
            this.getMessages(this.filter);
        },
        clearFilter() {
            this.filterMessages({
                daterange: "7",
                status: "",
                order_id: "",
            });
        },
    },
};
</script>

<style scoped>
</style>